export default Object.freeze({
  TABLE_TIT: '쇼핑몰 기본정보',
  TABLE_TIT_DISCLAIMER: '* 필수입력',
  SHOPPING_MALL_MODIFICATION: '쇼핑몰 수정',
  MALL_NAME: '쇼핑몰명',
  MALL_NO: '쇼핑몰번호',
  MALL_ADDRESS: '쇼핑몰 주소',
  MALL_COUNTRYCD: '판매대상국가',
  MALL_COUNTRYCD_NONE: '국가 선택',
  MALL_COUNTRYCD_KR: '대한민국',
  MALL_COUNTRYCD_US: '미국(대륙)',
  MALL_COUNTRYCD_U2: '미국(알래스카,하와이)',
  MALL_COUNTRYCD_JP: '일본 (JPY)',
  MALL_COUNTRYCD_CN: '중국 (CNY)',
  MALL_TIMEZONE: '시간대 선택',
  TIMEZONE_NONE: '시간대 선택',
  TIMEZONE_ASIA_SEOUL: 'UTC+9 대한민국・일본',
  TIMEZONE_GMT_Z: 'UTC+0',
  TIMEZONE_AMERICA_EASTERN: 'UTC-5 미국 Eastern',
  TIMEZONE_AMERICA_CENTRAL: 'UTC-6 미국 Central',
  TIMEZONE_AMERICA_MOUNTAIN: 'UTC-7 미국 Mountain',
  TIMEZONE_AMERICA_PACIFIC: 'UTC-8 미국 Pacific',
  MALL_URL_INFO: '쇼핑몰 접속설정',
  MALL_URL_INTRO: '인트로페이지',
  MALL_URL_DOMAIN: '도메인',
  PRODUCT_DETAIL_URI_TOOL_TIP: `· 쇼핑몰 상품상세 페이지 전체 URL 중 쇼핑몰 도메인 뒤에 붙는 URI만 입력하세요.
  · 상품번호는 {productNo} 로 입력하세요.
  · 모바일 경로를 별도로 사용중인 경우 '모바일 상품상세 URI'을 추가로 입력하셔야 합니다.
  · 예시
  &nbsp;&nbsp; 상품상세 URL이 https://nhncommerce.shopby.co.kr/product-detail?productNo=123456789 인 경우
  인풋박스에는 아래와 같이 입력하세요. (각 스킨별 확인 필요)
  &nbsp;&nbsp;&nbsp;&nbsp;- 오로라 : /pages/product/product-detail.html?productNo={productNo}
  &nbsp;&nbsp;&nbsp;&nbsp;- 오로라 리액트 : /product-detail?productNo={productNo}
  &nbsp;&nbsp;&nbsp;&nbsp;- 어나더 : /pages/product/view.html?productNo={productNo}
  · 스킨을 변경하면 상품상세 URL이 변경되오니 실제 사용하시는 쇼핑몰의 상품상세 URL을 꼭 확인해 주세요. (ex. 네이버쇼핑 등의 상품 ep 설정)`,
  PC_PRODUCT_DETAIL_URI: 'PC 상품상세 URI',
  MOBILE_PRODUCT_DETAIL_URI: '모바일 상품상세 URI',
  MALL_URL_GOTO: '도메인 연결',
  MALL_USE_SET: '사용여부 설정',
  MALL_USE: '사용여부',
  MALL_USED: '사용함',
  MALL_USELESS: '사용 안 함',
  JOIN_APPROVE_CONFIG: '회원가입 승인 사용설정',
  APPROVE_NOT_USED: '승인 절차 없이 가입',
  APPROVE_USED: '승인 후 가입',
  JOIN_APPROVE_CONFIG_TOOLTIP_1: `· '승인 후 가입' 설정 시 회원가입한 후, 운영자가 회원가입 승인 처리를 하면 회원가입 완료 상태로 변경됩니다.
  `,
  JOIN_APPROVE_CONFIG_TOOLTIP_2: `· '승인 후 가입' 설정 시 회원은 승인대기 상태로 등록되며, 운영자가 승인 처리를 하지 않은 경우 <span class="text-red">승인대기 상태 회원은 회원가입일 기준 60일 경과 후 삭제 처리</span>됩니다.
  `,
  JOIN_APPROVE_CONFIG_TOOLTIP_3: `· '승인 후 가입' 으로 설정한 상태에서 '승인 절차 없이 가입' 으로 변경 시 승인대기 상태인 회원은 자동으로 승인 처리되지 않으므로 승인이 필요한 경우 수동으로 승인 처리가 필요합니다.
  `,
  JOIN_APPROVE_CONFIG_TOOLTIP_4: `· '승인 후 가입' 설정 시 SMS(또는 알림톡), 이메일 알림은 '회원가입 승인완료' 템플릿 사용여부를 설정하여 발송 가능합니다.
  `,
  JOIN_APPROVE_CONFIG_TOOLTIP_5: `- 이메일 사용설정 : <a href="/pro/member/email/manage/config" target="_blank">설정 바로가기></a>
  `,
  JOIN_APPROVE_CONFIG_TOOLTIP_6: `- SMS 사용설정 : <a href="/pro/member/sms/main/config" target="_blank">설정 바로가기></a>
  `,
  JOIN_APPROVE_CONFIG_TOOLTIP_7: `- 알림톡 사용설정 : <a href="/pro/member/kakao/notification/config" target="_blank">설정 바로가기></a>
  `,
  DISCONNECT: '접속불가',
  MEMBER_CERTIFICATION: '회원인증',
  ADULT_CERTIFICATION: '성인인증',
  MALL_URL: '쇼핑몰 주소',
  CHECK_DUPLICATION: '중복 확인',
  MALL_PCURL: 'PC웹',
  MALL_MOURL: '모바일웹',
  MALL_AOSURL: 'AOS 모바일 앱 정보',
  MALL_IOSURL: 'iOS 모바일 앱 정보',
  MALL_APPTOKEN: '모바일앱 관리',
  WEB_OPEN: '바로가기',
  APP_OPEN: '앱 정보보기',
  URL_PLACEHOLDER: '20자 이내로 기입해주세요.',
  MOVE_TO_URL: '신청하기',
  ADDRESS_INPUT_MESSAGE: '쇼핑몰 주소를 입력해주세요.',
  CONTRACT_FILES: '국내 사업자 계약 서류 관리',
  CONTRACT_FILES_HIGHLIGHT1:
    'ⓘ ‘사업자등록증’, ‘통장사본‘, ‘법인인감증명서’ 를 제외한 계약 서류를 등록해주세요. (최대 5개)',
  CONTRACT_FILES_HIGHLIGHT2: 'ⓘ 등록된 계약 서류는 파트너사에서 확인 후, 필수 사항으로 등록합니다.',
  CONTRACT_FILES_HIGHLIGHT3:
    'ⓘ 파일은 문서(doc, docx, xls, xlsx), 이미지(jpg, jpeg, gif, png, pdf) 파일 등록이 가능하며,용량은 1개/10MB 미만 입니다.',
  CONTRACT_FILES_HIGHLIGHT4: 'ⓘ 안내 사항은 최대 1,000자까지 입력할 수 있습니다.',
  INCORRECT_FILE: '파일 확장자명을 확인해주세요.',
  CONTRACT_FILES_HIGHLIGHT5: 'ⓘ ‘계좌증빙서류‘ 를 제외한 계약 서류를 등록해주세요. (최대 5개)',
  CONTRACT_FILES_TITLE: '계약 서류',
  CONTRACT_FILES_LABEL: '서류',
  CONTRACT_FILES_FIND: '파일찾기',
  CONTRACT_FILES_DOWNLOAD: '다운로드',
  CONTRACT_FILES_GUIDE: '안내 사항',
  CONTRACT_FILES_PLACEHOLDER:
    '파트너사의 계약 서류 작성 시, 파트너사 안내 사항을 입력하세요.(최대 1,000자)\n(ex. 인감 날인된 계약서 원본은 우편으로 발송해주세요. 발송 주소: OOO)',
  CONTRACT_FILES_OVERSEAS: '해외 사업자 계약 서류 관리',
  CONTRACT_FILE_TOOLTIP1:
    '· ‘사업자등록증’, ‘통장사본‘, ‘법인인감증명서’ 를 제외한 계약 서류를 등록해주세요. (최대 5개)\n· 등록된 계약 서류는 파트너사에서 확인 후, 필수 사항으로 등록합니다.\n· 파일은 문서(doc, docx, xls, xlsx), 이미지(jpg, jpeg, gif, png, pdf) 파일 등록이 가능하며,용량은 1개/10MB 미만 입니다.\n· 안내 사항은 최대 1,000자까지 입력할 수 있습니다.',
  CONTRACT_FILE_TOOLTIP2:
    '· ‘계좌증빙서류‘ 를 제외한 계약 서류를 등록해주세요. (최대 5개)\n· 등록된 계약 서류는 파트너사에서 확인 후, 필수 사항으로 등록합니다.\n· 파일은 문서(doc, docx, xls, xlsx), 이미지(jpg, jpeg, gif, png, pdf) 파일 등록이 가능하며,용량은 1개/10MB 미만 입니다.\n· 안내 사항은 최대 1,000자까지 입력할 수 있습니다.',
  BANK_TITLE: '계좌정보',
  BANK_ACCOUNT: '계좌번호',
  BANK_PLACEHOLDER: '숫자만 입력하세요',
  BANK_DEPOSITORNAME: '예금주',
  SMS_EMAIL_TIT: 'SMS/e-mail 발송 사용 설정',
  SMS_EMAIL_DESCRIPTION1: "ⓘ SMS와 e-mail 발송 기능을 사용하시려면, 아래 항목을 '사용함'으로 설정해주세요.",
  SMS_EMAIL_DESCRIPTION2: 'ⓘ SMS 발송/e-mail 발송은 1개 이상 꼭 사용함으로 설정해야 합니다.',
  SMS_EMAIL_DESCRIPTION3: 'ⓘ SMS와 e-mail은 수동발송(광고성/정보성)과 자동발송(정보성)으로 사용이 가능합니다.',
  SMS_EMAIL_DESCRIPTION4:
    'ⓘ SMS와 e-mail 수동발송 기능을 사용하시려면, TOAST Cloud 프로젝트의 SMS/e-mail Appkey를 등록해 주셔야 합니다.',
  SMS_EMAIL_DESCRIPTION5: "ⓘ Appkey는 'TOAST Cloud>CONSOLE>프로젝트 설정' 에서 확인가능합니다.",
  SMS_EMAIL_DESCRIPTION6: 'ⓘ 자동 SMS를 사용하시려면, 발신번호를 등록해 주셔야 합니다.',
  SMS_EMAIL_DESCRIPTION7:
    'ⓘ SMS/e-mail 수동발송 금액은 TOAST Cloud 이용금액에 포함되어 청구되며, 자동발송 금액은 NCP가 부담합니다.',
  SMS_TIT: 'SMS 발송',
  SMS_APPKEY: 'SMS Appkey',
  SMS_APPKEY_DESCRIPTION: 'SMS Appkey가 등록되어 있어야 SMS 수동발송이 가능합니다.',
  SMS_APPKEY_PLACEHOLDER: 'SMS Appkey를 입력해주세요.',
  SMS_SENDER: '자동 SMS 발신번호',
  SMS_SENDER_PLACEHOLDER: '발신번호를 등록해주세요.',
  SMS_SENDER_ADD: '발신번호 등록/내역',
  SMS_SENDER_DESCRIPTION: '발신번호가 등록되어있어야 자동SMS 발송이 가능합니다.',
  EMAIL_TIT: 'e-mail 발송',
  EMAIL_APPKEY: 'e-mail Appkey',
  EMAIL_APPKEY_PLACEHOLDER: 'e-mail Appkey를 입력해주세요.',
  EMAIL_APPKEY_DESCRIPTION: 'e-mail Appkey가 등록되어 있어야 e-mail 수동발송이 가능합니다.',
  SERVICE_CENTER_TIT: '고객센터 정보',
  SERVICE_CENTER_HIGHLIGHT1: 'ⓘ 고객센터 전화번호는 발신번호 인증이 가능한 전화번호를 입력해주세요.',
  SERVICE_CENTER_HIGHLIGHT2:
    'ⓘ 고객센터 e메일주소는 수신용 e메일주소와 발송전용 e메일 주소 설정을 따로 하실 수 있습니다.',
  SERVICE_CENTER_HIGHLIGHT3: 'ⓘ e메일 발송 시, 설정한 쇼핑몰명과 함께 보여집니다. ex) 바이와우 <noreply@nhn.com>',
  SERVICE_CENTER_PHONENO: '고객센터 전화번호',
  SERVICE_CENTER_EMAIL: '고객센터 이메일',
  SERVICE_CENTER_OUTBOUNDEMAIL: '발송전용 이메일',
  SERVICE_CENTER_EMAILCOPY: "'고객센터 e메일'과 동일",
  SERVICE_CENTER_DESCRIPTION: '자동메일 발송 시, 기본 발송 계정으로 설정됩니다',
  ERROR: '입력형식이 잘못되었습니다',
  JOINCONFIG_TIT: '회원 인증 설정',
  JOIN_CONFIG: '회원 설정',
  USABLE_DORMANT_SETTING_TITLE: '휴면회원 사용여부 설정',
  MALLJOINCONFIG_TH: '쇼핑몰회원',
  MALLJOINCONFIG_USED: '인증 사용여부',
  MALLJOINCONFIG_TYPE: '인증종류 설정',
  MALLJOINCONFIG_TYPE_PHONE: '휴대폰인증',
  MALLJOINCONFIG_KCP_PHONE_AUTH: 'NHN KCP 휴대폰인증 설정',
  MALLJOINCONFIG_TYPE_SMS: 'SMS인증',
  MALLJOINCONFIG_TYPE_EMAIL: '이메일인증',
  MALLJOINCONFIG_TYPE2: '인증 시점 설정',
  MALLJOINCONFIG_TYPE2_JOIN_TIME: '회원가입 시',
  MALLJOINCONFIG_TYPE2_PAYMENT_TIME: '최초 상품 구매 시',
  MALLJOINCONFIG_TYPE2_AFTER_JOIN_TIME: '가입완료시',
  OPENIDJOINCONFIG_TH: '오픈아이디회원',
  SNS_JOINCONFIG_TIT: 'SNS 간편 회원가입 설정',
  SNS_JOINCONFIG_HIGHLIGHT1:
    'ⓘ 사용하려는 간편가입의 client ID와 secret key는 한번 저장 후 변경이 불가합니다. 정확하게 입력해주세요.',
  SNS_JOINCONFIG_HIGHLIGHT2: 'ⓘ 부득이하게 변경이 필요하다면, NCP 담당자에게 연락해주세요.',
  SNS_JOINCONFIG_PAYCO_TH: '페이코 간편가입',
  SNS_JOINCONFIG_CHOOSE: '선택',
  SNS_JOINCONFIG_NAVER_TH: '네이버 간편가입',
  SNS_JOINCONFIG_KAKAO_TH: ' 카카오 간편가입',
  SNS_JOINCONFIG_FACEBOOK_TH: '페이스북 간편가입',
  SNS_JOINCONFIG_LINE_TH: '라인 간편가입',
  SNS_URL_TIT: 'SNS 간편 회원가입 로그인 URL 설정',
  SNS_URL_PC: 'PC 웹',
  SNS_URL_MOBILE: '모바일웹',
  SNS_URL_AOS: 'AOS 모바일앱',
  SNS_URL_IOS: 'iOS 모바일',
  SHOPPING_CART_TIT: '장바구니 설정',
  SHOPPING_CART_HIGHLIGHT:
    "ⓘ '동일 옵션 표기 단위' 항목은 저장 후 변경이 불가합니다. 부득이하게 변경이 필요하다면, NCP 담당자에게 연락해주세요.",
  SHOPPING_CART_TH1: '보관 기간',
  SHOPPING_CART_TH2: '보관 수량',
  SHOPPING_CART_TH3: '동일 옵션 표기 단위',
  SHOPPING_CART_NOLIMIT: '제한없음',
  SHOPPING_CART_MAX: '최대',
  SHOPPING_CART_MAX_TIP1: '일 까지 보관 가능',
  SHOPPING_CART_MAX_TIP2: '개 까지 보관 가능',
  SHOPPING_CART_ROW: '행 추가',
  SHOPPING_CART_QUANTITY: '수량 추가',
  SHOPPING_CART_POP_CONTENT: '동일한 옵션 추가 시 장바구니에 옵션을 담는 형식에 대한 설정값',
  ACCUMULATIONCONFIG_TIT: '적립금 설정',
  ACCUMULATIONCONFIG_TH1: '상품 적립 사용 여부',
  ACCUMULATIONCONFIG_TH2: '회원 적립 혜택 사용 여부',
  ACCUMULATIONCONFIG_POP_CONTENT: ` <div class="notice_box no_mark">• 적립금 설정은 상품 적립금과 회원 적립금으로 구분되며 선택 시 각 영역에 일괄 적용됩니다.<br><span class="pl-10">- 상품 적립 사용 여부 선택 시 적용되는 위치: 상품등록/수정 &gt; 판매정보 &gt; 적립금 적립</span><br><span class="pl-10">- 회원 적립 혜택 사용 여부 선택 시 적용되는 위치:
  회원관리 &gt; 회원등급 관리/회원그룹 관리 &gt; 등급 적립금 혜택/그룹 적립금
  혜택</span><br><br>• 적립 사용 여부와 관계없이 지급된 적립금은 상품의 '적립금 사용' 설정에 따라 주문 시 적용됩니다.<br>• 적립금 설정은 운영관리
&gt; 적립금 관리에서 상세 설정 하지 않을 경우 '0'으로 적용됩니다.</div>`,
  PRODUCTCONFIG_TIT: '상품 설정',
  PRODUCTCONFIG_HIGHLIGHT: "ⓘ 'WMS 연동', '안전재고 설정(WMS 연동 사용 시)' 항목은 저장 후 변경이 불가합니다.",
  PRODUCTCONFIG_TH: '카테고리 수수료',
  PRODUCTCONFIG_TH2: '상품상세 URL',
  PRODUCTCONFIG_TH3: 'WMS 연동',
  PRODUCTCONFIG_COMPANY: '업체명',
  PRODUCTCONFIG_DESCRIPTION: '최초 저장 시, 수정이 불가합니다.',
  PRODUCTCONFIG_TH4: '안전재고 설정',
  PRODUCTCONFIG_SAFETYSTOCKBOUNDARY: 'WMS 연동재고',
  PRODUCTCONFIG_MORETHAN: '개 이상 시, 안전재고',
  PRODUCTCONFIG_LESSTHAN: '미만 시, 안전재고',
  PRODUCTCONFIG_PLACEHOLDER: '숫자만 입력',
  PRODUCTCONFIG_POP_CONTENT: `<div class="notice_box no_mark">
  <div>쇼핑몰 상품상세 페이지 URL 중에서 상품번호를 부분을 \${mallProductNo}라고 입력하세요.</div>
  <div><br>(예시)</div>
  <div>상품상세 URL이 <span>http://shoppingmall.com/goods?goodscode=1234567</span> 인
    경우<br><span>http://shoppingmall.com/goods?goodscode=\${mallProductNo}</span> 로 입력하세요.</div>
</div>`,
  PRODUCTCONFIG_ALERT: 'WMS 연동재고 개수와 같거나 작은 수를 입력해주세요.',
  ORDERCONFIG_TIT: '정산 설정',
  ORDERCONFIG_TH1: '정산유형',
  ORDERCONFIG_SETTLEMENTPERIODTYPE1: '월정산',
  ORDERCONFIG_SETTLEMENTPERIODTYPE2: '주정산',
  ORDERCONFIG_SETTLEMENTPERIODTYPE3: '일정산',
  ORDERCONFIG_TH2: '정산기준시점',
  DELIVERY_ING: '배송중',
  DELIVERY_DONE: '배송완료',
  BUY_CONFIRM: '구매확정',
  ORDERCONFIG_TH3: '정산예정일(국내파트너)',
  PER_MONTH: '매월',
  PER_DAY: '일 (휴일일 경우',
  YESTERDAY: '전일',
  TOMORROW: '익일',
  SETTLEMENT_DAY: '에 지급)',
  ORDERCONFIG_TH4: '지급비율(국내파트너)',
  ORDERCONFIG_TH5: '정산예정일(해외파트너)',
  ORDERCONFIG_TH6: '지급비율(해외파트너)',
  ORDERCONFIG_PAY_TIT: '결제수단 노출설정',
  ORDERCONFIG_PAY_HIGHLIGHT:
    'ⓘ 결제수단 설정을 네이버페이 주문형 사용함으로 설정하여도 연동 개발이 먼저 되어있어야 실제 사용이 가능합니다.',
  ORDERCONFIG_PAY_TH1: '네이버페이 주문형',
  ORDERCONFIG_PAY_TH2: '배송비 부과 방식',
  ORDERCONFIG_PAY_BUNDLE_SHIPPING: '묶음배송비',
  ORDERCONFIG_PAY_DELIVERY_TEMPLATE: '배송비템플릿',
  ORDERCONFIG_DELIVERY_TIT: '주문처리기간 설정',
  ORDERCONFIG_ORDER_TIT: '주문 설정',
  ORDERCONFIG_SETTLEMENT_TIT: '정산 설정',
  ORDERCONFIG_ORDER_DELIVERY: '주문 자동처리기간',
  ORDERCONFIG_AUTH_FOR_CHILD: '만 14세 미만 비회원 주문 시 본인인증',
  ORDERCONFIG_CUSTOMER_CAN_CANCEL: '고객 취소/반품/교환 신청 기능',
  ORDERCONFIG_DELIVERY_HIGHLIGHT:
    'ⓘ 네이버페이 주문형 주문 건은 NCP의 주문처리기간 설정을 사용하지 않고, 네이버페이 설정에 따라 적용됩니다.',
  ORDERCONFIG_DELIVERY_TH1: '자동 배송완료 (국내)',
  ORDERCONFIG_DELIVERY_TH2: '자동 배송완료 (해외)',
  ORDERCONFIG_DELIVERY_TH3: '자동 구매확정',
  ORDERCONFIG_DELIVERY_TH4: '미입금 주문 자동취소',
  PAY_TYPE_ACCOUNT: '무통장입금',
  PAY_TYPE_VIRTUAL_ACCOUNT: '가상계좌',
  SHIPPINGCONFIG_TIT: '배송 설정',
  SHIPPINGCONFIG_TH: '배송지 입력 URL',
  ORDER_DATE: '주문일 기준',
  SHIPPING_START: '배송시작일 기준',
  SHIPPING_END: '배송완료일 기준',
  AFTER_WORKING_DAY: '영업일 이후',
  AFTER_DAY: '일 이후',
  AUTO_DELIVERY_END: '자동 배송완료 처리',
  AUTO_CONFIRM: '자동 구매확정 처리',
  AUTO_CANCEL: '자동 취소',
  EXCLUDE_HOLIDAY: '(주말, 공휴일 제외하고 계산)',
  SAVE: '쇼핑몰 등록',
  CANCEL: '취소',
  ORDERCONFIG_PAY_POP_CONTENT: ` <div class="notice_box no_mark">
  <ul>
    <li>
      <div>네이버페이 주문형 주문 건은 전체주문조회/주문상세 페이지에서 네이버페이 주문번호와 네이버페이 상품주문번호가 추가 표기됩니다.</div>
    </li>
    <li>
      <div>네이버페이 주문형 주문 건은 결제일로부터 3영업일 이내에 발송처리가 불가한 경우, 네이버페이 판매자센터에서 ‘발송지연 안내’ 처리를 하셔야 패널티가
        부과되지 않습니다.</div>
    </li>
    <li>
      <div>구매자 주문일로부터 75일 이상 출고 처리가 지연된 주문 건은 각각의 주문상태에 따라 90일째 자동 구매확정 또는 고객에게 자동 환불됩니다.</div>
    </li>
    <li>
      <div>배송비 및 추가비용의 상세금액은 네이버페이 판매자센터를 통해 확인이 가능합니다. (네이버페이에서 처리한 금액으로 인해 NCP 어드민과 금액이 상이할 수
        있습니다.)</div>
    </li>
    <li>
      <div>NCP 어드민에서 배송보류 처리가 불가합니다. 배송보류 처리가 필요할 경우 네이버페이 판매자센터에서 '발송지연 안내'처리를 진행해 주세요.</div>
    </li>
    <li>
      <div>네이버페이 주문형 주문 건은 통계 데이터에 집계되나, 정산 데이터에는 집계되지 않습니다. 따라서 통계와 매출내역 조회 메뉴의 금액이 상이할 수
        있습니다.</div>
    </li>
    <li>
      <div>네이버페이 주문형 주문의 정산은 네이버페이 판매자센터에서 제공하는 정산 자료를 사용해 주세요.</div>
    </li>
  </ul>
</div>`,
  ORDERCONFIG_DELIVERY_POP_CONTENT: `<div class="notice_box no_mark">
<ul>
  <li>
    <div>네이버페이 주문형 주문 건은 NCP의 배송비 산출 기본 정책을 따르지 않고 선택한 별도 부과 방식에 따라 배송비가 책정됩니다.</div>
  </li>
  <li>
    <div>부과 방식에 따라 네이버페이 주문서와 쇼핑몰 주문서의 초도 배송비와 클레임 배송비가 상이할 수 있습니다.</div>
  </li>
  <li>
    <div>사용중인 부과 방식을 변경할 경우 1) 네이버페이 주문서로 연동 시점, 2) 네이버페이 주문 후 NCP로 주문데이터를 연동하는 시점에서 네이버페이와
      NCP의 배송비가 다르게 부과 및 인식될 수 있습니다. 검토 후 변경해 주세요.</div>
  </li>
  <li>
    <div>묶음배송비: 상품의 배송비템플릿이 속한 묶음 그룹 단위로 최소/최대 기준 배송비를 한번 부과하는 방식입니다.(NCP 배송비 산출 방식과 동일)</div>
  </li>
  <li>
    <div>배송비템플릿: 묶음 그룹과 관계없이 각 상품의 배송비템플릿 기준으로 배송비를 각각 계산하여 부과하는 방식입니다.</div>
  </li>

  <div>[1. 최대부과 예시]</div>
  <div>조건) 상품A: 유료 배송(3000원)/ ’가’ 묶음그룹, 상품B: 조건부 무료 배송(2500원)/ ’가’ 묶음그룹</div>
  <div>- 묶음배송비 선택 시 네이버페이 배송비: 3000원 부과, 쇼핑몰 배송비: 3000원 부과</div>
  <div>- 배송비템플릿 선택 시 네이버페이 배송비: 5500원 부과(3000원+2500원), 쇼핑몰 배송비: 3000원 부과</div>
  <div>[2. 최소부과 예시]</div>
  <div>조건) 상품A: 유료 배송(3000원)/ ’나’ 묶음그룹, 상품B: 조건부 무료 배송(2500원)/ ’나’ 묶음그룹</div>
  <div>- 묶음배송비 선택 시 네이버페이 배송비: 2500원 부과, 쇼핑몰 배송비: 2500원 부과</div>
  <div>- 배송비템플릿 선택 시 네이버페이 배송비: 5500원 부과(3000원+2500원), 쇼핑몰 배송비: 2500원 부과</div>
</ul>
</div>`,
  SHIPPINGCONFIG_POP_CONTENT: `<div class="notice_box no_mark">
<div>배송지 입력 URL은 주문 후 배송지를 나중에 입력하는 페이지 URL이며</div>
<div>배송지 관련 코드 부분을 \${encryptedShippingNo} 라고 입력하세요. </div>
<div><br>(예시)</div>
<div>배송지 입력 URL이 <span>http://shoppingmall.com/orders/shipping-info?code=abcd1234</span> 인 경우</div>
<div><span>http://shoppingmall.com/orders/shipping-info?code=\${encryptedShippingNo}</span> 로 입력하세요.</div>
</div>`,
  NO_MALLNAME_ALERT: '쇼핑몰명을 입력하세요.',
  NO_SCEMAIL_ALERT: '고객센터 이메일 주소를 입력하세요.',
  NO_OBEMAIL_ALERT: '발송전용 이메일 주소를 입력하세요.',
  NO_COUNTRY_ALERT: '국가를 선택해주세요.',
  NO_TIMEZONE_ALERT: '시간대를 선택해주세요.',
  NO_FIRST_ACCOUNT_ALERT: '첫번째 계좌번호를 반드시 입력해주세요.',
  NO_ACCOUNT_BANKACCOUNT_ALERT: '입력하신 계좌정보를 확인해주세요.',
  NO_ACCOUNT_BANK_ALERT: '입력하신 계좌정보의 은행을 선택해주세요.',
  NO_SCPHONE_ALERT: '고객센터 전화번호를 입력하세요.',
  NO_PRODUCT_URL_ALERT: '상품상세 URL을 입력해주세요.',
  NO_CORRECT_PRODUCT_URL_ALERT: '상품상세 URL을 정확히 입력해주세요. (상품상세 URL 입력 안내 참고)',
  NO_SMS_OR_EMAIL_ALERT: 'SMS / e-mail 발송 중 1개 이상 사용함으로 설정하세요.',
  NO_JOIN_ALERT: '최소 1개의 가입설정은 사용함으로 선택해주세요. (쇼핑몰회원, 간편회원)',
  NO_JOIN_CONTENT_ALERT: '선택한 간편가입의 정보를 입력해주세요.',
  NO_WMSUSED_CONFIG_ALERT: '안전재고 설정값을 입력하세요.',
  ADD_MALL_CONFIRM: '쇼핑몰 등록을 완료하시겠습니까?',
  NO_SHIPPINGCONFIG_URL_ALERT: '배송지 입력 URL을 정확히 입력해주세요. (배송지 입력 URL 안내 참고)',
  ADD_MALL_SUCCESS_ALERT: '저장이 완료되었습니다.',
  ADD_MALL_CANCEL_CONFIRM: '쇼핑몰 등록을 취소하시겠습니까?',
  EDIT_MALL_SUCCESS_ALERT: '수정이 완료되었습니다.',
  MOBILEAPP_POP_CONTENT: `<div class="notice_box no_mark" style="line-height:15px;">• 신청완료 되시면 쇼핑몰관리 &gt; 쇼핑몰수정&gt; 모바일앱 관리 &gt;모바일앱 관리 바로가기 버튼이
  노출됩니다.<br>• 모바일앱 관리 사용요금은 NCP 사용요금과 별도로 추가 부과됩니다.<br>• 모바일앱 (Mobile App) 작업을 위해서는 모바일웹 (Mobile
  Web) 도메인 정보가 필요합니다.<br>• 모바일앱 관리 신청 완료 하시면 모바일앱 관리 메뉴 접속이 가능하십니다.</div>`,
  STANDING_POINT_TH: '입점 표준 계약서',
  SELECT_BANK: '은행선택',
  STANDING_POINT_REGISTER: '저장',
  STANDING_POINT_UPDATE: '변경',
  STANDING_POINT_DELETE: '삭제',
  STANDING_POINT_DESCRIPTION: '· 파트너사 입점 계약 시, 동의 받을 계약 내용을 등록할 수 있습니다.',
  BANK_EDIT: '계좌정보수정',
  BANK_CREATE: '계좌정보등록',
  PERSONALINFO_TIT: '개인정보 이용내역 안내 발송 설정',
  PERSONALINFO_HIGHLIGHT1:
    'ⓘ 개인정보 이용안내 고지 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률 제 30조 2 (개인정보 이용내역의 통지) 에 의거하여개인정보 이용내역 안내를 매년1회 회원에게 발송 해야합니다.',
  PERSONALINFO_HIGHLIGHT2:
    'ⓘ 운영관리>e-mail 관리>자동 e-mail 설정 에서 해당 템플릿 ‘사용’ 인 경우만 설정된 자동발송일자가 적용되어발송 됩니다.',
  PERSONALINFO_HIGHLIGHT3:
    'ⓘ 자동발송일자는 한번 저장 후 변경이 불가합니다. 부득이하게 변경이 필요하시면 NCP 담당자에게 연락해주세요.',
  PERSONALINFO_TH: '자동발송 일자 설정',
  PERSONALINFO_SPAN1: '매달',
  PERSONALINFO_SPAN2: '설정',
  PERSONALINFO_HIGHLIGHT4: '회원가입 이후 매년 1년 이내 해당월 설정한 일자에 발송처리 됩니다.',
  MARKETINGINFO_TIT: '마케팅 수신동의 이용현황 발송 설정',
  MARKETINGINFO_HIGHLIGHT1:
    'ⓘ 마케팅수신동의 이용현황 안내 : 광고성 메일/SMS/푸시 수신동의 정보통신망법에 의거 2년마다 회원 대상으로 발송 해야합니다.',
  MARKETINGINFO_HIGHLIGHT2:
    'ⓘ 운영관리>e-mail 관리>자동 e-mail 설정 에서 해당 템플릿 ‘사용’ 인 경우만 설정된 자동발송일자가 적용되어발송 됩니다.',
  MARKETINGINFO_HIGHLIGHT3:
    'ⓘ 자동발송일자는 한번 저장 후 변경이 불가합니다. 부득이하게 변경이 필요하시면 NCP 담당자에게 연락해주세요.',
  MARKETINGINFO_HIGHLIGHT4: '마케팅 수신동의일자 기준으로 2년 내 해당월 설정한 일자에 발송처리 됩니다.',
  API_TIT: '개발 연동 정보',
  API_HIGHLIGHT1: 'ⓘ 클라이언트 아이디: 프론트에서 API 호출 시 해당하는 쇼핑몰을 판단할 수 있는 key값',
  API_HIGHLIGHT2: 'ⓘ 외부연동 키: 외부 서버에서 NCP의 서버 호출 시 판단할 수 있는 key값',
  API_HIGHLIGHT3: 'ⓘ API 점검: API 점검 등의 이유로 클라이언트 접근을 막아야 할 때 사용',
  API_TH1: '클라이언트 아이디',
  API_TH2: '외부 연동 키',
  API_TH3: 'API 점검',
  API_BTN: 'API 점검 진행',
  EDIT_BTN: '변경사항 저장',
  SENDNO_POPUP_TH: '발신번호',
  SENDNO_POPUP_DESCRIPTION1: '유선 전화번호: 02-YYY-YYYY (지역 번호 포함하여 등록)',
  SENDNO_POPUP_DESCRIPTION2: '대표 전화번호: 15YY, 16YY, 18YY (번호 앞에 지역 번호 사용 금지)',
  SENDNO_POPUP_DESCRIPTION3: '공통 서비스 식별 번호: 0N0 (번호 앞에 지역번호 사용 금지)',
  SENDNO_POPUP_DESCRIPTION4: '최소 8자리 ~ 최대 11자리까지의 발신 번호 입력 가능',
  SENDNO_POPUP_DESCRIPTION5: '존재하지 않는 번호 대역으로 메시지 전송 불가',
  SENDNO_POPUP_DESCRIPTION6: '(예: 070-0YYY, 070-1YYY, 010-0YYY, 010-1YYY)',
  SENDNO_POPUP_TH2: '통신서비스 이용 증명원',
  SENDNO_POPUP_DESCRIPTION7: '등록할 발신번호에 해당하는 통신서비스 이용 증명원을 첨부하시기 바랍니다.',
  SENDNO_POPUP_DESCRIPTION8: '통신서비스 이용 증명원이란, 이용자 본인이 사용하는 번호임을 증명하는 서류입니다.',
  SENDNO_POPUP_DESCRIPTION9:
    '가입한 통신사의 고객센터에서 발급받을 수 있으며, 자세한 발급방법은 해당 통신사에 문의하시기 바랍니다.',
  SENDNO_POPUP_DESCRIPTION10: '담당자가 등록한 서류 심사 후 영업일 기준 1~2일 내 승인이 완료됩니다.',
  SENDNO_POPUP_DESCRIPTION11: '발신번호를 추가로 등록하실 경우, 등록완료 되기 전까지 기존 발신번호로 발송되며',
  SENDNO_POPUP_DESCRIPTION12: '등록 완료 후 부터 추가한 발신번호로 발송됩니다.',
  SENDNO_POPUP_PLACEHOLDER: '등록할 발신번호를 입력해주세요.',
  SENDNO_POPUP_ADDFILE: '파일찾기',
  SENDNO_POPUP_DOWNLOADFILE: '다운로드',
  SENDNO_POPUP_CONFIRM: '발신번호 등록 요청',
  SENDNO_POPUP_LP_TITLE: '발신번호 등록내역',
  SENDNO_POPUP_LP_TH1: '등록요청 일시',
  SENDNO_POPUP_LP_TH2: '발신번호',
  SENDNO_POPUP_LP_TH3: '상태',
  REQUESTSENDNO_TIT: '발신번호 등록하기',
  CARTPOPUP_TIT: '동일 옵션 추가 설정',
  NOT_APPROVED: '미승인',
  APPROVED: '승인완료',
  ACCUMULATIONPOPUP_TIT: '적립금 설정',
  PRODUCTPOPUP_TIT: '상품상세 URL 안내',
  ORDERPAYPOPUP_TIT: '네이버페이 주문형 이용 안내',
  ORDERDELIVERYPOPUP_TIT: '네이버페이 주문형 배송비 부과 방식 설정 안내',
  SHIPPINGPOPUP_TIT: '배송지 입력 URL 안내',
  MOBILEAPPGUIDANCEPOPUP_TIT: '모바일 앱 관리 안내',
  STANDING_POPUP_TITLE: '입점 표준계약서',
  STANDING_POPUP_HIGHLIGHT:
    'ⓘ 입력된 ‘입점 표준계약서’ 의 내용은 ‘파트너 등록’ 시, 파트너사에 따라 적용 또는 수정할 수 있습니다.',
  STANDING_POPUP_TH: '내용',
  STANDING_POPUP_SUCCESS_MSG: '저장되었습니다.',
  STANDING_POPUP_ERR_CONTENT_NULL: '내용을 입력해 주세요.',
  STANDING_DELETE_ALERT: '입점 표준계약서를 삭제하시겠습니까?',
  EDIT_SAVE_ALERT: '변경 된 내용을 저장하시겠습니까?',
  MODIFY_SAVE_SUCESS_ALERT: '쇼핑몰 수정 완료',
  DOMAINS_VALIDATE: '사용 가능한 쇼핑몰 주소입니다.',
  HELP_MALL_URL_INFO: '· 입력한 정보로 쇼핑몰의 PC/모바일 도메인 주소가 기본 생성됩니다.',
  HELP_MALL_URL_INTRO:
    '· 인트로페이지란 이용자가 쇼핑몰에 접근하기 전 출력되는 별도 페이지를 의미합니다.<br/>· 인트로페이지 설정은 쇼핑몰 반영 시까지 일정 시간이 소요될 수 있습니다.<br/>· 접속불가 설정 시, 쇼핑몰 접근 시에 접속불가 화면이 대신 출력되며 쇼핑몰의 접근이 불가합니다.<br/>· 회원인증 설정 시, 쇼핑몰 접근 시에 회원인증을 위한 별도 화면이 대신 출력되며 로그인 후 쇼핑몰 접근이 가능합니다.<br/>· 성인인증 설정 시, 쇼핑몰 접근 시에 성인인증을 위한 별도 화면이 대신 출력되며 성인인증 후 쇼핑몰 접근이 가능합니다.<br/>· 성인인증 인트로페이지는 휴대폰 인증 설정 서비스 사용 시에만 사용할 수 있습니다.',

  PAYMENT_SETTING_NOTICE_TITLE: '결제수단 노출 설정 안내',
  PAYMENT_SETTING_NOTICE:
    '<strong>결제수단 노출 설정 안내 </strong>\n ' +
    '· 일반결제/에스크로결제 방식의 결제수단은 전자결제(PG) 서비스 신청 후 사용이 가능합니다. <a href="/pro/configuration/payment/pg" target="_blank">전자결제(PG) 설정></a>\n' +
    '· 간편결제 방식의 결제수단은 각 간편결제 서비스 신청 후 사용이 가능합니다. <a href="/pro/configuration/payment/payco" target="_blank">페이코 결제 설정></a>  <a href="/pro/configuration/easy-payment/naver" target="_blank">네이버페이 설정></a>\n' +
    '· 노출설정을 ‘노출 안 함’으로 설정한 결제수단은 쇼핑몰 주문서 작성 페이지에서 결제 수단으로 노출되지 않습니다.\n' +
    '· 단, ‘노출 안 함’으로 설정하더라도 클레임 처리 시에는 주문 시 사용한 결제수단을 이용하여 처리가 가능합니다.\n' +
    '· 결제수단 노출설정은 쇼핑몰 반영 시까지 일정 시간이 소요될 수 있습니다.',
  AUTHENTICATE_SECURITY_NOTICE: '쇼핑몰 보안을 위하여 보안인증 후 계좌번호를 등록하실 수 있습니다.',
  ACCOUNT_TOOL_TIP: '계좌정보 설정은 쇼핑몰 반영 시까지 일정 시간이 소요될 수 있습니다.',
  NO_BANK_MESSAGE: '입력하신 계좌정보의 은행을 선택해주세요.',
  NO_ACCOUNT_MESSAGE: '계좌번호를 입력해주세요.',
  NO_DEPOSITOR_NAME_MESSAGE: '예금주명 입력해주세요.',
  RECEIVING_AUTH_CODE_ALERT:
    '인증번호를 발송했습니다.\n인증번호가 오지 않으면 휴대폰번호 정보가 NHN커머스 회원정보와 일치하는지 확인해 주세요.',

  MEMBER_AUTHENTICATION_TOOL_TIP:
    '<strong>회원 인증 설정 시 주의 및 안내사항</strong>\n' +
    "· 회원 인증 수단은 SMS(또는 알림톡), 이메일, 휴대폰인증 의 사용설정이 '사용함'인 경우에만 설정 가능합니다.\n" +
    "· 회원가입 항목 설정 기능을 사용하시는 경우 회원 인증에 필요한 가입 항목을 '사용함'으로 설정해 주셔야 합니다.\n" +
    '&nbsp;&nbsp;&nbsp;&nbsp;- 이메일인증 : 이메일\n' +
    '&nbsp;&nbsp;&nbsp;&nbsp;- SMS인증 : 휴대폰번호\n' +
    '&nbsp;&nbsp;&nbsp;&nbsp;- 휴대폰인증 : 이름, 휴대폰번호, 생년월일, 성별\n' +
    '· SMS 또는 알림톡의 경우, SMS포인트가 부족한 경우 인증 SMS가 발송되지 않을 수 있습니다.\n' +
    '· 회원 인증 설정의 사용여부, 인증 시점, 인증수단의 설정 변경 시, 변경 전 인증이 완료된 회원의 정보는 변경되지 않습니다.\n  ' +
    '&nbsp;&nbsp;&nbsp;&nbsp;- 설정 변경 시, 변경 시점 이후 신규회원부터 변경된 인증설정이 적용됩니다.\n  ' +
    '&nbsp;&nbsp;&nbsp;&nbsp;- 쇼핑몰 회원이 ‘회원정보수정‘시에는 최초 인증시점의 수단이 아닌, 현재 설정된 인증수단으로 인증해야만 회원정보수정이 가능합니다.\n' +
    '· 휴대폰인증 설정 사용 시, 아이디 찾기 / 비밀번호 찾기 / 휴면해제 시 인증수단으로 사용되며, 성인인증 사용 상품, 성인인증 인트로페이지 사용 시 성인인증 수단으로 사용됩니다.',

  DORMANT_SETTING_TOOL_TIP: `• 2023년 9월 15일 개인정보보호법 개정에 따라 '개인정보 유효기간제'가 폐지되어 업체 운영방침에 따라 자율적으로 휴면회원 배치 사용여부를 설정할 수 있습니다.
  • 휴면회원 기능을 '사용 안 함'으로 설정 시 다음 휴면전환 처리 시점에 휴면회원 처리 대상자도 휴면 처리되지 않습니다. 단, 기존 처리된 휴면회원에 대해서는 영향이 없습니다. 
  • 휴면회원 기능을 '사용 안 함'으로 설정 시 SMS/알림톡/이메일 사용설정 여부와 상관없이 휴면전환사전안내 알림 발송이 되지 않습니다. 
  `,

  MEMBER_AUTHENTICATION_PHONE_AUTHENTICATION: 'NHN KCP 휴대폰인증을 먼저 신청해주셔야 사용이 가능합니다.',
  MEMBER_AUTHENTICATION_CONFIRM: '변경 시, 신규회원부터 인증이 불가합니다.\n변경하시겠습니까?',
  MEMBER_AUTHENTICATION_TYPE_CONFIRM: '변경 시, 신규회원부터 {0}으로 인증수단이 변경됩니다.\n변경하시겠습니까?',
  MEMBER_AUTHENTICATION_TYPE_ALERT: '인증수단으로 ‘휴대폰인증’을 사용 중입니다.\n인증수단 설정을 확인해주세요.',
  MEMBER_AUTHENTICATION_PHONE_AUTHENTICATION_CONFIRM:
    '변경 시, 회원의 성인인증 및 본인인증 기능이 불가합니다.\n변경하시겠습니까?',
  AUTHENTICATION_BY_EMAIL: '이메일인증',
  SMS_AUTHENTICATION: 'SMS인증',
  AUTHENTICATION_BY_PHONE: '휴대폰인증',
  INTRO_PAGE_ALERT: '성인인증 인트로 페이지는 휴대폰 인증 사용 시에만 설정 가능합니다.',
  AUTH_FOR_CHILD_TOOL_TIP:
    '· 비회원 주문 시 본인인증을 통해 만 14세 미만 여부를 확인하여 만 14세 미만 아동의 주문을 제한할 수 있습니다.\n' +
    '· 휴대폰 인증 서비스 사용 시에만 해당 기능을 사용할 수 있습니다.\n' +
    '· 개인정보보호법에 의거하여, 만 14세 미만의 아동으로부터 개인정보 수집·이용·제공 동의를 받으려면, 법정 대리인의 동의가 필요하며,\n' +
    '이에 따라 만 14세 미만 아동의 주문을 제한할 필요가 있습니다. <a href="http://www.law.go.kr/법령/개인정보보호법/(20200805,16930,20200204)/제39조의3" target="_blank">관련법령 바로가기></a>',
  AUTH_FOR_CHILD_ALERT: '만 14세 미만 비회원 주문 시 본인인증은\n휴대폰 인증 사용 시에만 설정 가능합니다.',
  CUSTOMER_CANCEL_TOOL_TIP:
    '· 사용 안 함 설정 시 쇼핑몰에서 취소/교환/반품 신청 버튼이 노출되지 않으며, 고객의 취소/교환/반품 신청이 불가합니다.',
  SHOPPING_CART_TOOL_TIP: '· 이미 장바구니에 담긴 옵션과 동일한 옵션을 추가할 때 별도 행으로 추가됩니다.',
  MALL_NAME_VALIDATION: '쇼핑몰명을 입력해주세요.',
  MALL_ADDRESS_VALIDATION: '쇼핑몰 주소를 입력해주세요.',
  MALL_ADDRESS_LENGTH_VALIDATION: '쇼핑몰 주소를 6자 이상으로 입력해주세요.',
  MALL_ADDRESS_DUPLICATION: '이미 사용중인 쇼핑몰 주소입니다. 다시 입력해주세요.',
  MALL_PHONE_NO_VALIDATION: '고객센터 전화번호를 입력해주세요.',
  MALL_PHONE_NO_LENGTH_VALIDATION: '정확한 고객센터 전화번호를 입력해주세요.',
  PC_PRODUCT_URI_NO_LENGTH_VALIDATION: 'PC 상품상세 URI를 입력해주세요.',
  MALL_EMAIL_VALIDATION: '고객센터 이메일을 입력해주세요.',
  ACCOUNT_VALIDATION: '계좌번호를 1개 이상 입력해주세요.',
  PAY_TYPE_ALERT:
    '노출설정 불가 : {0} - {1}\n쇼핑몰에 연동되지 않은 결제수단은 노출설정이 불가합니다.\n새로고침 후 다시 시도해주세요.',
  NAVER_PAY_ALERT: '네이버페이 사용여부가 ‘사용 안 함’상태로\n결제수단 노출설정이 불가합니다.',
  NOTICE_MESSAGE:
    '쉽고 빠른 등록을 위해 쇼핑몰 신규 등록 시 <strong>간소화된 항목</strong>을 제공합니다.\n' +
    '등록 이후 <strong>[서비스 관리 -> 쇼핑몰관리 -> 쇼핑몰 수정]</strong> 페이지에서 운영과 관련된 세부 설정 사항들을 수정하여 관리할 수 있습니다.',

  //결제수단
  REALTIME_ACCOUNT_TRANSFER: '계좌이체',
  CREDIT_CARD: '신용카드',
  VIRTUAL_ACCOUNT: '가상계좌',
  ESCROW_VIRTUAL_ACCOUNT: '가상계좌',
  ESCROW_REALTIME_ACCOUNT_TRANSFER: '계좌이체',
  MOBILE: '휴대폰',
  ESCROW: '에스크로결제',
  GENERAL_PAY: '일반결제',
  EASY_PAY: '간편결제',
  PAYCO: 'PAYCO',
  EASY_PAYCO: 'PAYCO',
  NAVER_EASY_PAY: '네이버페이 결제형',
  NAVER_PAY: '네이버페이 주문형',
  ACCOUNT: '무통장 입금',
  PAYCO_POINT: '페이코 포인트',

  AUTHENTICATION_MESSAGE: "인증수단 설정은 '사용설정'이 사용함인 수단만 설정 가능합니다.",
  EMAIL_SETTING: '이메일 사용설정',
  SMS_SETTING: 'SMS 사용 설정',
  SETTING_LINK: '설정 바로가기',
  KCP_AUTHENTICATION: 'KCP 휴대폰인증 서비스 신청',

  SETTLEMENT_TYPE: '정산 유형',
  SETTLEMENT_MONTH_TYPE: '월정산',
  SETTLEMENT_PERIOD: '정산기준시점',
  DOMESTIC_SETTLEMENT_DAY: '정산예정일(국내파트너)',
  OVERSEA_SETTLEMENT_DAY: '정산예정일(해외파트너)',
  EXCLUDE_AUTO_BUY: '서비스상품군 자동 구매확정 대상에서 제외',
  EXCLUDE_AUTO_BUY_CONFIRM:
    '서비스상품군 자동 구매확정 대상에 포함 시 이전 생성된 서비스상품군에 대한 주문도 설정된 기간에 따라 자동 구매확정 처리됩니다. 서비스상품군을 처리 대상에 포함하시겠습니까?',
  AOS_MOBILE_DEEP_LINK: 'AOS 모바일 딥링크',
  IOS_MOBILE_DEEP_LINK: 'IOS 모바일 딥링크',
  AOS_MOBILE_DEEP_LINK_TOOL_TIP: `· Android 모바일 딥링크 인증을 위해 assetlinks.json 파일을 업로드 해 주세요.
    · 파일명과 확장자가 일치하여야 정상 업로드 가능합니다.
    · 대표로 지정하신 도메인에 보안서버가 설치되지 않을 경우 업로드가 불가합니다.
    · json 이외의 확장자 파일은 업로드 불가합니다.
    · 도메인이 변경될 경우 파일 내 도메인 정보를 업데이트하여 재 업로드를 해 주셔야 변경된 도메인으로 인증 가능합니다.
    · 도메인이 파일 업로드 외 딥링크 관련 문제 발생 시 모바일 APP 구축사로 문의해주세요.
    `,
  IOS_MOBILE_DEEP_LINK_TOOL_TIP: `· ios 앱 링크 인증을 위해 apple-app-site-association 파일을 업로드 해 주세요.
    · 파일명과 확장자가 일치하여야 정상 업로드 가능합니다.
    · 대표로 지정하신 도메인에 보안서버가 설치되지 않을 경우 업로드가 불가합니다.
    · json 이외의 확장자 파일은 업로드 불가합니다.
    · 도메인이 변경될 경우 파일 내 도메인 정보를 업데이트하여 재 업로드를 해 주셔야 변경된 도메인으로 인증 가능합니다.
    · 도메인이 파일 업로드 외 딥링크 관련 문제 발생 시 모바일 APP 구축사로 문의해주세요.
    `,
  MAIN_DOMAIN_SSL_NOT_INSTALLED: '보안서버가 설치되지 않은 도메인입니다.',
  CHECK_FILE_NAME: '파일명을 다시 확인해주세요.',
});
