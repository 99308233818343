






import AdminIframe from '@/components/iframe/AdminIframe.vue';

export default {
  components: { AdminIframe },
  computed: {
    remoteSrc() {
      const queryObject = { serviceType: 'PRO' };
      const queryString = new URLSearchParams(queryObject).toString();

      return process.env.VUE_APP_ADMIN_REMOTE_URL + '/configuration/payment/order-extra-config?' + queryString;
    },
  },
};
