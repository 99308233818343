import { GridProp } from '@/types';
import { headerWithTooptipRenderer } from '@/utils/grid/rendererUtils';
import { changeDateFormat, getDateDiff, getToday } from '@/utils/dateFormat';

export const getAdminSelectType = () => {
  return {
    searchType: [
      { name: window.$t('ALL'), value: 'ALL' },
      { name: window.$t('CONFIGURATION.ADMIN.ADMIN_ID'), value: 'ADMIN_ID' },
      { name: window.$t('CONFIGURATION.ADMIN.ADMIN_NAME'), value: 'ADMIN_NAME' },
      { name: window.$t('CONFIGURATION.ADMIN.ADMIN_EMAIL'), value: 'ADMIN_EMAIL' },
      { name: window.$t('CONFIGURATION.ADMIN.ADMIN_MOBILE'), value: 'ADMIN_MOBILE' },
      { name: window.$t('CONFIGURATION.ADMIN.ADMIN_PHONE'), value: 'ADMIN_PHONE' },
    ],
    departmentType: [{ name: window.$t('CONFIGURATION.ADMIN.DEPARTMENT'), value: '' }],
    jobPositionType: [{ name: window.$t('CONFIGURATION.ADMIN.JOB_POSITION'), value: '' }],
    jobDutyType: [{ name: window.$t('CONFIGURATION.ADMIN.JOB_DUTY'), value: '' }],
  };
};
export const getAdminCheckBoxOption = () => {
  return {
    status: {
      name: 'admin-status-checkbox',
      hasAll: true,
      noBrackets: true,
      data: [
        {
          id: 'all',
          value: '',
          display: window.$t('ALL_CHECK'),
        },
        {
          id: 'register',
          value: 'register',
          display: window.$t('CONFIGURATION.ADMIN.STATUS_REGISTER'),
        },
        {
          id: 'normal',
          value: 'normal',
          display: window.$t('CONFIGURATION.ADMIN.STATUS_NORMAL'),
        },
        {
          id: 'blockLogin',
          value: 'blockLogin',
          display: window.$t('CONFIGURATION.ADMIN.STATUS_BLOCK_LOGIN'),
        },
        // {
        //   id: 'expiration',
        //   value: 'expiration',
        //   display: window.$t('CONFIGURATION.ADMIN.STATUS_EXPIRATION'),
        // },
      ],
    },
    notLoginedLongTime: {
      name: 'admin-not-logined-long-time-checkbox',
      hasAll: false,
      noBrackets: true,
      data: [
        {
          id: 'longNoneLogin',
          value: true,
          display: window.$t('CONFIGURATION.ADMIN.STATUS_LONG_NONE_LOGIN'),
        },
      ],
    },
  };
};
export const getAdminGridOption = (): GridProp => {
  return {
    header: {
      columns: [
        {
          name: 'adminStatus',
          renderer: props => {
            props.tooltipId = 'adminStatus';
            return headerWithTooptipRenderer(props);
          },
        },
      ],
    },
    columns: [
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_ID'),
        name: 'adminId',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          return `<a href="${'/pro/configuration/management/admin/edit?adminNo=' + cell.row.adminNo}">${cell.value ||
            ''}</a>`;
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_NAME'),
        name: 'adminName',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_NUMBER'),
        name: 'adminNo',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (cell.row && cell.row.adminStatus === 'WAITING') {
            return '';
          }
          return cell.value || '';
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_ROLE'),
        name: 'adminRole',
        align: 'center',
        minWidth: 100,
        formatter: ({ value }) => {
          return value === 'MASTER'
            ? window.$t('CONFIGURATION.ADMIN.ADMIN_ROLE_MASTER')
            : window.$t('CONFIGURATION.ADMIN.ADMIN_ROLE_NORMAL');
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.AUTHORITY_GROUP'),
        name: 'authorityGroupName',
        align: 'center',
        minWidth: 200,
        formatter: ({ row, value }) => {
          return row.adminRole === 'MASTER' ? '-' : value.toString();
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.STATUS'),
        name: 'adminStatus',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (cell.value === 'ACTIVE') {
            let str = window.$t('CONFIGURATION.ADMIN.ADMIN_STATUS_ACTIVE');

            const lastLoginDateTime = cell.row.lastLoginDateTime as string;

            if (lastLoginDateTime) {
              const dateDiff = getDateDiff(changeDateFormat(lastLoginDateTime, 'YYYY-MM-DD'), getToday(), 'days');
              if (dateDiff > 90) {
                str += `<br><span class="text-red">(${window.$t(
                  'CONFIGURATION.ADMIN.ADMIN_STATUS_ACTIVE_LONG_NONE_LOGIN',
                )})</span>`;
              }
            }

            return str;
          } else if (cell.value === 'WAITING') {
            return window.$t('CONFIGURATION.ADMIN.ADMIN_STATUS_WAITING');
          } else if (cell.value === 'LOCKED') {
            return window.$t('CONFIGURATION.ADMIN.ADMIN_STATUS_LOCKED');
          }

          return '';
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_EMAIL'),
        name: 'email',
        align: 'center',
        minWidth: 250,
        formatter: cell => {
          if (!cell || !cell.row || !cell.row.contactInfo) {
            return '';
          }

          const contactInfo = cell.row.contactInfo as { email };

          return contactInfo.email || '';
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_MOBILE'),
        name: 'mobileNo',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (!cell || !cell.row || !cell.row.contactInfo) {
            return '';
          }

          const contactInfo = cell.row.contactInfo as { mobileNo };

          return contactInfo.mobileNo || '';
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.ADMIN_PHONE'),
        name: 'phoneNo',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (!cell || !cell.row || !cell.row.contactInfo) {
            return '';
          }

          const contactInfo = cell.row.contactInfo as { phoneNo };

          return contactInfo.phoneNo || '';
        },
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.DEPARTMENT'),
        name: 'departmentName',
        align: 'center',
        minWidth: 100,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.JOB_POSITION'),
        name: 'jobPositionName',
        align: 'center',
        minWidth: 100,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.JOB_DUTY'),
        name: 'jobDutyName',
        align: 'center',
        minWidth: 100,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.OTP_YN'),
        name: 'otp',
        align: 'center',
        minWidth: 100,
        whiteSpace: 'pre-line',
        formatter: ({ value: otp }) => {
          const used = !!otp?.['used'];
          
          return used ? 'Y' : 'N'
        },
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.CREATED_DATE_TIME'),
        name: 'createdDateTime',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.PASSWORD_UPDATE_DATE_TIME'),
        name: 'passwordUpdatedDateTime',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('CONFIGURATION.ADMIN.LAST_LOGIN_DATE_TIME'),
        name: 'lastLoginDateTime',
        align: 'center',
        minWidth: 200,
        whiteSpace: 'pre-line',
      },
      {
        header: window.$t('DELETE'),
        name: 'delete',
        align: 'center',
        minWidth: 100,
        formatter: cell => {
          if (cell && cell.row && cell.row.adminRole !== 'MASTER') {
            return `<button class="btnDel-sm">${window.$t('DELETE')}</button>`;
          }

          return '-';
        },
        whiteSpace: 'pre-line',
      },
    ],
    options: {
      keyColumnName: 'adminNo',
      pageOptions: {
        perPage: 30,
        page: 1,
      },
    },
    displayOptions: {
      hasExcelDownloadButton: false,
      hasSettingButton: false,
      headerTooltipOption: [
        {
          id: '#adminStatus',
          popupData: { data: { message: window.$t('CONFIGURATION.ADMIN.STATUS_TOOLTIP') } },
        },
      ],
    },
  };
};
export const getAdminToolTipOption = () => {
  return {
    searchStatus: {
      message: window.$t('CONFIGURATION.ADMIN.STATUS_LONG_NONE_LOGIN_TOOLTIP'),
    },
  };
};
