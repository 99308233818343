






















































































































































































































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  getUsesOrNot,
  i18nMallCreate,
  storageMaxQuantity,
  storagePeriod,
  UsedPhoneAuthenticationType,
} from '@/const/contents/configuration/mallCreate';
import { GetOrderConfigsOrder, OrderconfigsCartResponse } from 'ncp-api-supporter';
import { getSelectMonths } from '@/utils/dateFormat';

@Component({
  components: { TextInput, ToolTip, RadioGroup, SelectBox },
})
export default class OrderSetting extends Vue {
  @PropSync('orderConfig') private orderConfigSync!: GetOrderConfigsOrder['value']; //일단 필요없음
  @PropSync('cartConfig') private cartConfigSync!: OrderconfigsCartResponse['value'];
  @PropSync('claimsConfig') private claimsConfigSync!: boolean;
  @Prop({ required: true }) private readonly usedPhoneAuthentication!: UsedPhoneAuthenticationType;
  @Prop({ required: true }) private readonly isEditPage!: boolean;

  private getUsesOrNot = getUsesOrNot;
  private storageMaxQuantity = storageMaxQuantity;
  private storagePeriod = storagePeriod;
  private getSelectMonths = getSelectMonths;

  //TOdo api 수정 후 변경
  private authForChild = false;

  private onChangeAuthForChild(value) {
    if (value === true && this.usedPhoneAuthentication === 'KCP') {
      alert(i18nMallCreate('AUTH_FOR_CHILD_ALERT'));
      this.$nextTick(() => (this.authForChild = false));
    }
  }

  private handleExcludesAutoBuy() {
    const checkbox = this.$refs.excludesAutoBuy as HTMLInputElement;

    if (!this.orderConfigSync.excludesAutoBuyConfirmOfServiceProductGroup) {
      this.orderConfigSync.excludesAutoBuyConfirmOfServiceProductGroup = true;
      checkbox.checked = true;

      return;
    }

    const result = confirm(this.$t('MALL.FORM.EXCLUDE_AUTO_BUY_CONFIRM').toString());

    this.orderConfigSync.excludesAutoBuyConfirmOfServiceProductGroup = !result;
    checkbox.checked = !result;
  }
}
