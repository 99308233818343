import { RouteConfig } from 'vue-router';
import DefaultLayout from '@/views/DefaultLayout.vue';
import MarketingMain from '@/views/contents/marketing/basic/Main.vue';
import ProductConfig from '@/views/contents/marketing/ProductConfig.vue';
import GoogleAdMarketing from '@/views/contents/marketing/productConfig/GoogleAdMarketing.vue';
import GoogleAd from '@/views/contents/marketing/googleAd/GoogleAd.vue';
import GoogleAdConfig from '@/views/contents/marketing/GoogleAdConfig.vue';
import ProductManage from '@/views/contents/marketing/googleAd/ProductManage.vue';
import AccountManage from '@/views/contents/marketing/googleAd/AccountManage.vue';
import NaverShoppingConfig from '@/views/contents/marketing/naverShopping/NaverShoppingConfig.vue';
import NaverShoppingConfigIframe from '@/views/contents/marketing/naverShopping/NaverShoppingConfigIframe.vue';
import NaverShoppingProductConfig from '@/views/contents/marketing/naverShopping/NaverShoppingProductConfig.vue';
import AdManage from '@/views/contents/marketing/googleAd/AdManage.vue';
import Report from '@/views/contents/marketing/googleAd/Report.vue';
import ProductConfigIframe from '@/views/contents/marketing/ProductConfigIframe.vue';

export default (): RouteConfig => {
  return {
    path: '/marketing',
    name: 'Marketing',
    component: DefaultLayout,
    children: [
      {
        path: 'basic/main',
        name: 'MarketingMain',
        component: MarketingMain,
      },
      {
        path: 'naver-shopping',
        name: 'NaverShoppingConfigIframe',
        component: NaverShoppingConfigIframe,
      },
      {
        path: 'naver-shopping-prev',
        name: 'NaverShoppingConfig',
        component: NaverShoppingConfig,
      },
      {
        path: 'product-prev',
        name: 'ProductConfig',
        component: ProductConfig,
        redirect: { name: 'NaverShoppingProductConfig' },
        children: [
          {
            path: 'google',
            name: 'GoogleAdMarketing',
            component: GoogleAdMarketing,
          },
          {
            path: 'naver-shopping',
            name: 'NaverShoppingProductConfig',
            component: NaverShoppingProductConfig,
          },
          // {
          //   path: 'facebook',
          //   name: 'FaceBookMarketing',
          //   component: FaceBookMarketing,
          // },
        ],
      },
      {
        path: 'product',
        name: 'ProductConfigIframe',
        component: ProductConfigIframe,
        children: [
          {
            path: ':content',
            name: 'ProductConfigIframe',
            component: ProductConfigIframe,
          },
        ],
      },
      {
        path: 'google-ad-config',
        name: 'GoogleAdConfig',
        component: GoogleAdConfig,
        redirect: { name: 'ProductManage' },
        children: [
          {
            path: 'product-manage',
            name: 'ProductManage',
            component: ProductManage,
          },
          {
            path: 'account-manage',
            name: 'AccountManage',
            component: AccountManage,
          },
          {
            path: 'ad-manage',
            name: 'AdManage',
            component: AdManage,
          },
          {
            path: 'report',
            name: 'Report',
            component: Report,
          },
        ],
      },
      {
        path: 'google-ad',
        name: 'GoogleAd',
        component: GoogleAd,
      },
    ],
  };
};
