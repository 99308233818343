






















































































































































































































































import { Vue, Component, Prop, PropSync, Watch, Ref } from 'vue-property-decorator';
import RadioGroup from '@/components/common/RadioGroup.vue';
import ToolTip from '@/components/common/tooltip/ToolTip.vue';
import TextInput from '@/components/common/input/TextInput.vue';
import SelectBox from '@/components/common/SelectBox.vue';
import {
  i18nMallCreate,
  getIntroPageOptions,
  alertMessage,
  MallType,
  UsedPhoneAuthenticationType,
} from '@/const/contents/configuration/mallCreate';
import {
  DomainsValidate,
  NCPResponse,
  PutProductsConfigurationRequest,
  PatchMallsMallNoConfigurationDeepLinkRequest,
} from 'ncp-api-supporter';
import { namespace } from 'vuex-class';
const adminStore = namespace('admin');

const EMAIL_SEPARATOR = '@';
const AOS_FILE_NAME = 'assetlinks.json';
const IOS_FILE_NAME = 'apple-app-site-association.json';
type DeepLinkType = 'aos' | 'ios';

@Component({
  components: { TextInput, ToolTip, RadioGroup, SelectBox },
})
export default class BasicInfo extends Vue {
  @adminStore.Getter('getGodoSno') private readonly godoSno!: number;
  @PropSync('mall') private mallSync!: MallType;
  @PropSync('productConfig') private productConfigSync!: PutProductsConfigurationRequest['data'];
  @PropSync('deepLink') private deepLinkSync!: PatchMallsMallNoConfigurationDeepLinkRequest['data'];
  @Prop({ required: true }) private readonly isEditPage!: boolean;
  @Prop({ required: true }) private readonly usedPhoneAuthentication!: UsedPhoneAuthenticationType;
  @Prop({ required: true }) private readonly mallNo!: string;
  private getIntroPageOptions = getIntroPageOptions;
  private prevIntroPagePc = '';
  private aosFIleUrl = '';
  private iosFIleUrl = '';
  private aosFileName = '';
  private iosFileName = '';

  @Ref() private readonly mallNameRef: HTMLInputElement;
  @Ref() private readonly mallIdRef: HTMLInputElement;
  @Ref() private readonly mallPhoneNoRef: HTMLInputElement;
  @Ref() private readonly mallEmailIdRef: HTMLInputElement;
  @Ref() private readonly mallEmailDomainRef: HTMLInputElement;
  @Ref() private readonly productDetailUrlRef: HTMLInputElement;
  @Ref() private readonly aosFileInputRef: HTMLInputElement;
  @Ref() private readonly iosFileInputRef: HTMLInputElement;

  @Watch('mallSync.introRedirection.pc')
  private savePrevMemberAuthenticationType(_, prevValue) {
    this.prevIntroPagePc = prevValue;
  }

  created() {
    this.isEditPage && this.init();
  }

  private async init() {
    this.fetchProductDetailUri();
    this.fetchDeepLinkInfo();
  }

  private async fetchDeepLinkInfo() {
    const { data } = await this.$api.getMallsMallNoConfigurationDeepLink({
      params: { mallNo: this.mallNo },
    });

    this.aosFIleUrl = data.aosDeepLinkUrl;
    this.iosFIleUrl = data.iosDeepLinkUrl;
    if (data.aosDeepLinkUrl) {
      this.aosFileName = AOS_FILE_NAME;
    }

    if (data.iosDeepLinkUrl) {
      this.iosFileName = IOS_FILE_NAME;
    }
  }

  private async fetchProductDetailUri() {
    const { data } = await this.$api.getProductsConfiguration({
      params: { mallNo: Number(this.mallNo) },
    });

    this.productConfigSync.wmsUsed = data.wmsUsed;
    this.productConfigSync.productDetailUrl = data.productDetailUrl;
    this.productConfigSync.productDetailUrlForMobile = data.productDetailUrlForMobile;
    this.productConfigSync.categoryCommissionUsed = data.categoryCommissionUsed;
    this.productConfigSync.displayStock = data.displayStock;
  }

  public async validateMall(): Promise<boolean> {
    const { mallName, mallId, serviceCenter } = this.mallSync;
    const { productDetailUrl } = this.productConfigSync;
    const { emailId, emailDomain } = this.dividedEmail;
    const { emptyMallName, emptyMallAddress, phoneNo, phoneNoLength, email, pcProductUri } = alertMessage;
    if (!this.isValidation(mallName !== '', 'mallNameRef', emptyMallName)) return false;
    if (this.isEditPage === false) {
      if (!this.isValidation(mallId !== '', 'mallIdRef', emptyMallAddress)) return false;
      if (!(await this.checkDuplication(false))) {
        this.mallIdRef.focus();
        return false;
      }
    }

    if (!this.isValidation(serviceCenter.phoneNo !== '', 'mallPhoneNoRef', phoneNo)) return false;
    if (!this.isValidation(serviceCenter.phoneNo.length > 7, 'mallPhoneNoRef', phoneNoLength)) return false;
    if (!this.isValidation(emailId !== '', 'mallEmailIdRef', email)) return false;
    if (!this.isValidation(emailDomain !== '', 'mallEmailDomainRef', email)) return false;
    if (!this.isValidation(!!productDetailUrl?.length, 'productDetailUrlRef', pcProductUri)) return false;

    return true;
  }

  private isValidation(trueCondition: boolean, ref: string, alertMessage: string): boolean {
    if (trueCondition) return true;

    this[ref].focus();
    alert(i18nMallCreate(alertMessage));
    return false;
  }

  private async checkDuplication(isSuccessAlert = true): Promise<boolean> {
    if (this.mallSync.mallId === '') {
      alert(i18nMallCreate('ADDRESS_INPUT_MESSAGE'));
      return false;
    }

    const request = {
      params: {
        mallId: this.mallSync.mallId,
      },
    };

    try {
      const { data }: NCPResponse<DomainsValidate> = await this.$api.getDomainsValidate(request);
      if (data.isSuccess === true) {
        if (isSuccessAlert) alert(i18nMallCreate('DOMAINS_VALIDATE'));
        return true;
      }
      alert(data.errorMsg);

      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  private onChangeEmail() {
    const { emailId, emailDomain } = this.dividedEmail;
    this.$set(this.mallSync.serviceCenter, 'email', emailId + EMAIL_SEPARATOR + emailDomain);
  }

  private get dividedEmail(): { emailId: string; emailDomain: string } {
    if (this.mallSync.serviceCenter.email) {
      const [emailId, emailDomain] = this.mallSync.serviceCenter.email.split(EMAIL_SEPARATOR);
      return { emailId, emailDomain };
    } else {
      return {
        emailId: '',
        emailDomain: '',
      };
    }
  }

  private onChangeIntroPage(value: string) {
    if (value === 'ONLY_ADULT' && this.usedPhoneAuthentication === 'NONE') {
      alert(i18nMallCreate('INTRO_PAGE_ALERT'));
      this.$nextTick(() => this.$set(this.mallSync.introRedirection, 'pc', this.prevIntroPagePc));
    }
  }

  private async checkMainDomains(): Promise<boolean> {
    if (this.mallSync.url.pc.includes(process.env.VUE_APP_SHOPBY_DOMAIN)) {
      return true;
    }

    const { data } = await this.$commerceApi.getMallsMallNoSsl({
      pathParams: { mallNo: this.mallNo },
    });

    if (!data.length) {
      return false;
    }

    const mainDomainInfo = data.find(domain => domain.isMain);
    return mainDomainInfo.sslStatus === 'INSTALLED';
  }

  private removeCurrentFile(type: DeepLinkType) {
    if (type === 'aos') {
      this.deepLinkSync.aosUploadedFileName = '';
      this.aosFileName = '';
      this.aosFIleUrl = '';
    } else {
      this.deepLinkSync.iosUploadedFileName = '';
      this.iosFileName = '';
      this.iosFIleUrl = '';
    }
  }

  private async postFile(fileData: FormData, type: DeepLinkType) {
    const { data } = await this.$api.postStorage({ data: fileData });

    if (type === 'aos') {
      this.deepLinkSync.aosUploadedFileName = data.uploadedFileName;
      this.aosFileName = data.originalFileName;
    } else {
      this.deepLinkSync.iosUploadedFileName = data.uploadedFileName;
      this.iosFileName = data.originalFileName;
    }
  }

  private async addFile(e, type: DeepLinkType) {
    if (!(await this.checkMainDomains())) {
      alert(i18nMallCreate('MAIN_DOMAIN_SSL_NOT_INSTALLED'));
      return;
    }

    const [file] = e.target.files;

    if (
      (type === 'ios' && file.name !== IOS_FILE_NAME) ||
      (type === 'aos' && file.name !== AOS_FILE_NAME) ||
      !file.name.toLowerCase().match('json')
    ) {
      alert(i18nMallCreate('CHECK_FILE_NAME'));
      return;
    }

    const fileData = new FormData();
    fileData.append('file', file);
    fileData.append('container', 'app-deep-link');
    await this.postFile(fileData, type);

    (this.$refs[type === 'aos' ? 'aosFileInputRef' : 'iosFileInputRef'] as HTMLInputElement).value = '';
    type === 'aos' ? (this.aosFIleUrl = '') : (this.iosFIleUrl = '');
  }
}
